import NewAppointment from 'AppointmentBox';
import ModalConfirmSMSChange, { modalConfirmSMSChangeRef } from 'AppointmentBox/ModalConfirmSMSChange';
import BlockHoursModal from 'BlockHourBox';
import NotFoundPage from 'NotFound';
import QuickBookingBox from 'QuickBooking';
import ShopWrapperLayout from 'ShopWrapperLayout';
import TurnAppointmentsPage from 'Turn/TurnAppointments';
import TurnLayout from 'Turn/TurnLayout';
import { PassCodeVerify } from 'Turn/TurnModals/PassCodeVerify';
import TurnSchedulerPage from 'Turn/TurnScheduler';
import { ConfigProvider } from 'antd';
import LoadingPage from 'components/LoadingPage';
import AppointmentListPage from 'pages/AppointmentListPage';
import BookingPage from 'pages/BookingPage';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useInitSocket, { SocketContext } from 'services/useSocket';
import store from 'store';
import { verifyPinCode } from 'widgets/ModalVerifyAdminPin';

const AppLayout: React.FC = () => {
  const socketContext = useInitSocket();
  return (
    <SocketContext.Provider value={socketContext}>
      <Routes>
        <Route path='/:shop_id' element={<ShopWrapperLayout />} >
          <Route path='' element={<BookingPage />} />
          <Route path='list' element={<AppointmentListPage />} />
          <Route path='turn' element={<TurnLayout />}>
            <Route path='' element={<TurnSchedulerPage />} />
            <Route path='list' element={<TurnAppointmentsPage />} />
          </Route>
        </Route>
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </SocketContext.Provider>
  );
};

const App: React.FC = () => (
  <ConfigProvider
    theme={{
      'token': {
        'colorPrimary': '#F05326',
        'colorPrimaryBg': '#FFF1F6',
        'colorPrimaryActive': '#F05326',
        'colorText': '#1D2129',
        'colorTextSecondary': '#767676',
        'colorBorder': '#86909C',
        'colorBorderSecondary': '#CECECE',
        'colorFill': '#E5E6EB',
        'colorFillSecondary': '#E5E5E5',
        'colorFillTertiary': '#F6F7FC',
        'colorBgLayout': '#E5E5E5',
        'colorBgSpotlight': '#1D2129',
        'colorBgMask': 'rgba(0, 0, 0, 0.2)',
        'colorTextBase': '#1d2129',
        'colorBgBase': '#ffffff',
        'colorWarning': '#ff7d00',
        'colorError': '#f53f3f',
        'colorInfo': '#6fabb6',
        'fontSize': 16,
        'sizeStep': 4,
        'borderRadius': 5,
        'borderRadiusXS': 2,
        'wireframe': false
      },
    }}
  >
    <Provider store={store}>
      <AppLayout />
      <ToastContainer />
      <NewAppointment />
      <BlockHoursModal />
      <QuickBookingBox />
      <ModalConfirmSMSChange ref={modalConfirmSMSChangeRef} />
      <PassCodeVerify ref={verifyPinCode} />
      <LoadingPage />
    </Provider>
  </ConfigProvider>
);

export default App;
