import { createSlice } from '@reduxjs/toolkit';
import { IStaffUI } from 'types/staff';
import actions from '../actions/shop';
import { IScheduleBooking } from 'services/types/shop';
import { ICategoryUI } from 'types/category';
import { IServiceUI } from 'types/service';
import { ICustomerUI } from 'types/customer';

const _scheduleBooking = {
  ['Sun']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
  ['Sat']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
  ['Fri']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
  ['Thu']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
  ['Wed']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
  ['Tue']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
  ['Mon']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
};

const NAME_REDUCER = 'shop';

export type IStateShop = {
  staffs: IStaffUI[],
  scheduleBooking: IScheduleBooking | null,
  categories: ICategoryUI[],
  allServices: IServiceUI[],
  customers: ICustomerUI[],
  setting: {
    appointmentVerifyPinCode: boolean;
  }
}
const initialState: IStateShop = {
  staffs: [],
  scheduleBooking: _scheduleBooking,
  categories: [],
  allServices: [],
  customers: [],
  setting: {
    appointmentVerifyPinCode: false,
  }
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.category.success, (state, { payload }) => {
        state.categories = payload;
        const services: IServiceUI[] = [];
        state.categories.map(cate => {
          cate.services.map(s => {
            services.push({
              ...s,
              cateId: cate.id,
            });
          });
        });
        state.allServices = services;
      })
      .addCase(actions.staff.success, (state, { payload }) => {
        state.staffs = payload;
      })
      .addCase(actions.customer.success, (state, { payload }) => {
        state.customers = payload;
      })
      .addCase(actions.afterCreateCustomer, (state, { payload }) => {
        state.customers.pop();
        state.customers.unshift(payload);
      })
      .addCase(actions.afterCreateService, (state, { payload }) => {
        state.allServices.unshift(payload);
        state.categories = state.categories.map(o => {
          if (o.id?.toString() === payload.cateId?.toString()) return ({
            ...o,
            services: [payload, ...o.services],
          });
          return o;
        });
      })
      ;
  },
});

const shopReducer = Slice.reducer;
export default shopReducer;
