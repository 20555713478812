import { Modal as AntModal, Form, Input, InputRef, Spin } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useState, useTransition } from 'react';
import styled from 'styled-components';
import closeIcon from '../Close.png';
import NumberPad from './NumberPad';
import turnApis from 'Turn/services/apis';
import { MASTER_ADMIN_PIN_CODE } from 'Turn/services/constants';
import shopSelectors from 'services/selectors/shop';
type Configs = { title?: string, placeholder?: string };
export type PassCodeVerifyRef = {
  checkAdmin: (cb: (pinCode?: string) => void, config?: Configs) => void;
  forceVerifyAdmin: (cb: (pinCode?: string) => void, config?: Configs) => void;
  close: () => void;
  open: (cb: (pinCode: string) => Promise<boolean>, config?: Configs) => void;
};

export const PassCodeVerify = forwardRef<PassCodeVerifyRef>(({ }, ref) => {
  const [open, setOpen] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const [form] = Form.useForm();
  const [wrongPin, setWrongPin] = useState(false);
  const callback = useRef<(pinCode?: string) => void>(() => undefined);
  const callbackSuccess = useRef<((pinCode: string) => Promise<boolean>) | null>(null);
  const [loading, setLoading] = useState(false);
  const [configs, setConfigs] = useState<Configs | null>(null);
  const settings = shopSelectors.setting();

  const [, startTransition] = useTransition();

  const handleClose = () => {
    startTransition(() => {
      setOpen(false);
      callbackSuccess.current = null;
      callback.current = () => undefined;
      setWrongPin(false);
      form.resetFields();
      setConfigs(null);
      setLoading(false);
    });
  };

  const handleFinish = async (values: any) => {
    if (typeof callbackSuccess.current === 'function') {
      setLoading(true);
      const valid: boolean = await callbackSuccess.current(values.pinCode);
      if (valid) {
        handleClose();
      } else {
        setWrongPin(true);
      }
      setLoading(false);
      return;
    }

    if (values.pinCode === MASTER_ADMIN_PIN_CODE) {
      callback.current(values.pinCode);
      handleClose();
      return;
    }

    setLoading(true);
    try {
      const res: { data: { payload: any } } = await turnApis.checkPinCodeAdmin(values.pinCode);
      if (res.data.payload) {
        callback.current(values.pinCode);
        handleClose();
      } else {
        setWrongPin(true);
      }
    } catch (error) {
      setWrongPin(true);
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    checkAdmin(_success, _configs) {
      if (!settings.appointmentVerifyPinCode) return _success(MASTER_ADMIN_PIN_CODE);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
      setOpen(true);
      setWrongPin(false);
      form.resetFields();
      callbackSuccess.current = null;
      setConfigs(_configs || null);
      callback.current = _success;
    },
    forceVerifyAdmin(_success, _configs) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
      setOpen(true);
      setWrongPin(false);
      form.resetFields();
      callbackSuccess.current = null;
      setConfigs(_configs || null);
      callback.current = _success;
    },
    close() {
      setOpen(false);
    },
    open(callback, _configs) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
      setOpen(true);
      setWrongPin(false);
      form.resetFields();
      setConfigs(_configs || null);
      callbackSuccess.current = callback;
    },
  }));

  return (
    <AntModal
      centered
      open={open}
      title={null}
      footer={false}
      closable={false}
      destroyOnClose={false}
      width={'auto'}
    >
      <Spin spinning={loading}>
        <Container initialValues={{ pinCode: '' }} form={form} onFinish={handleFinish} onValuesChange={() => setWrongPin(false)}>
          <div className="modal-header">
            <span>{configs?.title || ''}</span>
            <button type='button' className='btn-close' onClick={() => setOpen(false)}>
              <img src={closeIcon} />
            </button>
          </div>
          <div className="modal-body">
            <div className="number-pad-placeholder">{configs?.placeholder || 'Enter Pin Code'}</div>
            <div className="wrap-number-input">
              <Form.Item name={'pinCode'} noStyle>
                <Input
                  style={{ borderColor: '#F05326', height: 65 }}
                  ref={inputRef}
                  className='number-input'
                  inputMode='none'
                  placeholder='ENTER PIN CODE'
                  maxLength={4}
                  autoFocus
                  type="password"
                />
              </Form.Item>
              <div style={{ textAlign: 'center', opacity: wrongPin ? 1 : 0 }}><p style={{ color: '#F05326' }}>Wrong Pin Code</p></div>
            </div>
            <Form.Item name={'pinCode'} noStyle>
              <NumberPad inputRef={inputRef} />
            </Form.Item>
          </div>
        </Container>
      </Spin>
    </AntModal>
  );
});

const usePassCodeVerify = (): [React.RefObject<PassCodeVerifyRef>, JSX.Element] => {
  const ref = useRef<PassCodeVerifyRef>(null);
  const context = <PassCodeVerify ref={ref} />;
  return [ref, context];
};

export default usePassCodeVerify;

const Container = styled(Form)`
background: #fff;
.modal-header {
  display: flex;
  padding: 0px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  height: 46px;
  border-bottom: 2px solid #EEE;

  span {
    flex: 1 0 0;
    color: #7B7B7B;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .btn-close {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 5.333px;
    justify-content: center;
    align-items: center;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.modal-body {
padding-top: 8px;
  .number-pad-placeholder {
    align-self: stretch;
    color: #7B7B7B;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 8px;
  }
  
  .wrap-number-input {
    padding: 0 16px;
    padding-bottom: 8px;
  }

  .number-input {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

`;